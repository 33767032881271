export default [
  {
    key: 'name',
    sortable: false,
    sortField: '',
    label: 'field.name',
  },
  {
    key: 'isEnable',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },

  {
    key: 'actions',
    label: 'general.action',
  },
]
